<template>
  <div class="flex mb-32">

    <div class="w-full sm:w-2/3 md:w-3/5 lg:w-4/5 xl:w-1/2 mx-auto">

      <h1 class="text-2xl font-bold mb-5">Add a new policy</h1>

      <form @submit.prevent="submit" class="card border border-blue-200 pt-16 pb-16 px-6 md:px-16 -mx-6 sm:mx-0">

        <div class="text-gray-600 mb-10">Set the amount range for this policy</div>

        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon"></span>
            <span>{{ getFormError(form) }}</span>
          </div>
        </template>

        <form-group
          type="money"
          left-icon="caret-down-circle-outline"
          name="low"
          :form="form"
          v-model="form.data.low.value"
        >
          Lowest Amount
        </form-group>

        <form-group
          type="money"
          left-icon="caret-up-circle-outline"
          name="high"
          :form="form"
          v-model="form.data.high.value"
        >
          Highest Amount
        </form-group>

        <button type="submit" class="btn btn-blue" :disabled="form.loading">
          <span v-if="form.loading">Saving</span>
          <span v-else>Save Policy</span>
        </button>

      </form>

    </div>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">

      <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-sm mb-10">
        Policy Added Successfully
      </div>

      <router-link :to="{name: 'expense-policies'}" class="btn btn-blue">
        View All Policies
      </router-link>
      
    </modal>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        form: this.$options.basicForm([
          'low',
          'high'
        ]),
      }
    },
    beforeMount() {
      this.getStaff(false);
    },
    mounted() {
    },
    methods: {
      async getPolicies(forced) {
        this.loadResource('policies', forced);
      },
      async submit() {
        if (!this.validateForm(this.form)) {
          return false;
        }

        this.form.loading = true;

        await this.sendRequest('corporate.expensePolicies.new', {
          data: {
            company_id: this.user.company_id,
            range: `${this.form.data.low.value} - ${this.form.data.high.value}`
          },
          success: () => {
            this.$refs.successModal.open();
            this.getPolicies(true);
          },
          error: error => {
            this.form.error = error;
          }
        });

        this.form.loading = false;
      }
    }
  }
</script>